body {
  text-align: center;
  font-family: Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  background: black;
}

div h1 h2 {
  margin: 0;
  padding: 0;
}

.main {
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  width: 100%;
  background-size: cover;
  background-position: 50% 25%;
  overflow: hidden;
  background-color: blue;
}
.main h1 {
  color: #ffee00;
  font-size: 4rem;
  margin: 20px 0;

}

.main h2 {
  color: white;
  font-size: 1.5rem;
  animation-name: slideIn;
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  margin: 0;
}

.image {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .main h1 {
    font-size: 3rem;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
  
  }
  to {
    opacity: 1;
  }
}


.mobile-main {
color:rgb(255, 238, 0);
display: flex;
gap: 35px;
flex-direction: column;
height: 100vh;
}

.mobile-main h1 {
  font-size: 1rem;
}

.pre-save-wrapper {
  color: #fff;
  font-size: 1rem;
  line-height: 3rem;
width: 1100px;

 
}

.svg-wrapper svg {
  cursor: pointer;
  margin-top: -140px;
  animation-name: slideIn;
  opacity: 0;
  animation-duration: 1s;
  animation-fill-mode: forwards;
margin-bottom: -80px;
}



.pre-save-wrapper :hover {
  cursor: pointer;
  /* border : 2px solid yellow; */
}

.spotify-iframe {
  margin-top: -300px;
}